<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">
                <div class="card-title align-items-start flex-column">
                  <h3 class="card-label font-weight-bolder text-dark">
                    Create Waste Log
                  </h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1"
                    >Log a new waste accumulation entry</span
                  >
                </div>
              </slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <div>
              <router-link to="/waste-logging" v-slot="{ href, navigate }">
                <a
                  :href="href"
                  class="btn btn-primary"
                  v-b-tooltip.hover
                  title="View All Waste Logs"
                  @click="navigate"
                >
                  <i class="fas fa-list pr-0"></i>
                </a>
              </router-link>
            </div>
          </template>
          <template v-slot:body>
            <div class="row">
              <form
                @submit.prevent="submit"
                class="form col-md-10 mx-auto mt-5"
                autocomplete="false"
                id="observation_form"
              >
                <div class="pb-5">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Select Waste Stream</label>
                        <multiselect
                          v-model="$v.stream.$model"
                          :options="streams"
                          track-by="name"
                          label="name"
                          placeholder="Select A Waste Stream"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.name }}</template
                          >
                        </multiselect>
                        <template v-if="$v.stream.$error">
                          <span
                            class="form-text text-danger"
                            v-if="!$v.stream.required"
                            >Waste stream is required
                          </span>
                        </template>
                        <span class="form-text text-muted"
                          >Please select a waste stream.</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Select Location</label>
                        <multiselect
                          v-model="location"
                          :options="locations"
                          track-by="name"
                          label="name"
                          placeholder="Select A Location"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.name }}</template
                          >
                        </multiselect>
                        <template v-if="$v.location.$error">
                          <span
                            class="form-text text-danger"
                            v-if="!$v.location.required"
                          >
                            Location is required
                          </span>
                        </template>
                        <span class="form-text text-muted"
                          >Please select a location.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Entry Date</label>
                        <b-form-datepicker
                          :max="max"
                          v-model="$v.timestamp.$model"
                          class="form-control form-control-solid form-control-lg"
                          :class="{ 'is-invalid': $v.timestamp.$error }"
                        ></b-form-datepicker>
                        <template v-if="$v.timestamp.$error">
                          <span
                            class="form-text text-danger"
                            v-if="!$v.timestamp.required"
                            >Date is required
                          </span>
                        </template>
                        <span class="form-text text-muted"
                          >Please pick your date.</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label
                          >Amount Accumulated
                          <span v-if="stream"
                            >(in {{ stream.unit.name }})</span
                          ></label
                        >
                        <input
                          class="form-control form-control-solid form-control-lg"
                          type="text"
                          placeholder="Amount consumed eg 500Bbl"
                          autocomplete="off"
                          :class="{ 'is-invalid': $v.amount.$error }"
                          v-model.trim="$v.amount.$model"
                        />
                        <template v-if="$v.amount.$error">
                          <span
                            class="form-text text-danger"
                            v-if="!$v.amount.required"
                          >
                            Amount accumulated is required
                          </span>
                        </template>
                        <span class="form-text text-muted"
                          >Please enter amount accumulated here.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Comment</label>
                    <textarea
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Comment"
                      value=""
                      rows="5"
                      v-model.trim="comment"
                    />
                    <span class="form-text text-muted"
                      >Please make a comment on this entry if any.</span
                    >
                  </div>
                </div>

                <div class="d-flex justify-content-end border-top pt-10 pb-10">
                  <div>
                    <router-link
                      to="/waste-logging"
                      v-slot="{ href, navigate }"
                    >
                      <a
                        :href="href"
                        class="btn btn-light-danger font-weight-bold px-9 py-4 mr-2"
                        @click="navigate"
                      >
                        Cancel
                      </a>
                    </router-link>
                    <button
                      :disabled="loading"
                      type="submit"
                      class="btn btn-primary font-weight-bold px-9 py-4"
                    >
                      <b-spinner
                        v-if="loading == true"
                        variant="light"
                        label="Spinning"
                      ></b-spinner>
                      <span v-if="loading == false"
                        >Submit Waste Accumulation</span
                      >
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.b-form-btn-label-control.form-control {
  height: 45px;
}
.form-group.hasError label {
  color: red;
}
.b-form-btn-label-control.form-control > .form-control {
  padding-top: 11px;
  font-size: 13px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PilotCard from "@/view/content/Card.vue";
import Multiselect from "vue-multiselect";
import WasteStreamService from "@/core/services/waste-stream.service.js";
import LocationService from "@/core/services/location.service.js";
import WasteService from "@/core/services/waste.service.js";
import { required, decimal } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
export default {
  props: {
    title: String
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    return {
      amount: "",
      stream: null,
      timestamp: null,
      location: null,
      comment: "",
      max: maxDate,
      locations: [],
      streams: [],
      loading: false
    };
  },
  validations: {
    amount: { required, decimal },
    stream: { required },
    timestamp: { required },
    location: { required }
  },
  components: {
    PilotCard,
    Multiselect
  },
  methods: {
    fullname({ firstname, lastname }) {
      return `${firstname} ${lastname}`;
    },
    getLocations() {
      LocationService.all()
        .then(response => {
          this.locations = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate company sectors at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getStreams() {
      WasteStreamService.all()
        .then(response => {
          this.streams = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate streams at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;

        var data = {
          waste_stream_id: this.stream.id,
          consumption_date: this.timestamp,
          location_id: this.location.id,
          amount: this.amount,
          comment: this.comment
        };

        WasteService.create(data)
          .then(() => {
            this.stream = null;
            this.timestamp = null;
            this.location = null;
            this.amount = "";
            this.comment = "";

            this.$nextTick(() => {
              this.$v.$reset();
            });

            Swal.fire({
              title: "Waste Log Submitted",
              text: "Waste log has been successfully submitted",
              icon: "success",
              heightAuto: false
            });
            this.loading = false;
          })
          .catch(error => {
            if (error.data) {
              this.makeToast("danger", error.statusText, error.data.message);
            } else {
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. please give it a minute and refresh page"
              );
            }
            this.loading = false;
          });
      }
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "currentToken"]),

    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Waste Logging", route: "waste-logging" },
      { title: "Log Waste Accumulation" }
    ]);
    this.getLocations();
    this.getStreams();
  }
};
</script>
